<template>
  <div class="smb-client-wrapper">
    <Loader v-if="isLoading" />
    <div class="client-info-container">
      <div class="container-item__title">Branding</div>
      <div v-if="isShowImagesBlock" class="logo-container-list">
        <div class="container-item__small-img">
          <div class="logo-title">Logo</div>
          <div
            class="small-img-container"
            v-if="getCurrentSmbCustomer.brand_logo && !isShowBrandLogoUploader"
          >
            <span class="icon-edit-png" @click="clickOnEditImgIcon('brandLogo')"
              ><img src="@/assets/img/icons/edit.png" alt=""
            /></span>
            <span class="icon-remove-png" @click="deleteImage('brand_logo')">
              <img src="@/assets/img/icons/remove.png" alt="" />
            </span>
            <img
              :src="getCurrentSmbCustomer.brand_logo"
              :alt="getCurrentSmbCustomer.brand_logo"
            />
          </div>
          <div class="small-img-container__upload" v-else>
            <SmbFileUploader
              elementId="brandLogo"
              :filesProp="brandLogo"
              isHideInput
              prependIcon="icon-upload-image"
              acceptProp="image/png, image/jpeg, image/bmp*"
              @dialogCanceledWithoutSelectingFiles="
                isShowBrandLogoUploader = false
              "
              @filesWasUpdated="
                uploadSelected(
                  $event,
                  'isShowBrandLogoUploader',
                  'brandLogo',
                  'brand_logo'
                )
              "
            />
            <span class="small-img-container__upload-text">Upload</span>
          </div>
        </div>
        <div class="container-item__small-img pl-4">
          <div class="logo-title">Brokerage</div>
          <div
            class="small-img-container"
            v-if="
              getCurrentSmbCustomer.brokerage_logo &&
              !isShowBrokerageLogoUploader
            "
          >
            <span
              class="icon-edit-png"
              @click="clickOnEditImgIcon('brokerageLogo')"
            >
              <img src="@/assets/img/icons/edit.png" alt="" />
            </span>
            <span
              class="icon-remove-png"
              @click="deleteImage('brokerage_logo')"
            >
              <img src="@/assets/img/icons/remove.png" alt="" />
            </span>
            <img
              :src="getCurrentSmbCustomer.brokerage_logo"
              :alt="getCurrentSmbCustomer.brokerage_logo"
            />
          </div>
          <div class="small-img-container__upload" v-else>
            <SmbFileUploader
              elementId="brokerageLogo"
              :filesProp="brokerageLogo"
              isHideInput
              prependIcon="icon-upload-image"
              acceptProp="image/png, image/jpeg, image/bmp"
              @dialogCanceledWithoutSelectingFiles="
                isShowBrokerageLogoUploader = false
              "
              @filesWasUpdated="
                uploadSelected(
                  $event,
                  'isShowBrokerageLogoUploader',
                  'brokerageLogo',
                  'brokerage_logo'
                )
              "
            />
            <span class="small-img-container__upload-text">Upload</span>
          </div>
        </div>
        <div class="container-item__small-img pl-4 mr-5">
          <div class="logo-title">Headshot</div>
          <div
            class="small-img-container"
            v-if="
              getCurrentSmbCustomer.brand_headshots &&
              getCurrentSmbCustomer.brand_headshots.length &&
              !isShowHeadshotUploader
            "
          >
            <span
              class="icon-edit-png"
              @click="clickOnEditImgIcon('brandHeadshots')"
            >
              <img src="@/assets/img/icons/edit.png" alt="" />
            </span>
            <span
              class="icon-remove-png"
              @click="deleteImage('brand_headshots', true)"
              ><img src="@/assets/img/icons/remove.png" alt=""
            /></span>
            <img
              :src="getCurrentSmbCustomer.brand_headshots[0]"
              :alt="getCurrentSmbCustomer.brand_headshots[0]"
            />
          </div>
          <div class="small-img-container__upload" v-else>
            <SmbFileUploader
              elementId="brandHeadshots"
              :filesProp="brandHeadshots"
              isHideInput
              custom-class=""
              prependIcon="icon-upload-image"
              acceptProp="image/png, image/jpeg, image/bmp"
              @dialogCanceledWithoutSelectingFiles="
                isShowHeadshotUploader = false
              "
              @filesWasUpdated="
                uploadSelected(
                  $event,
                  'isShowHeadshotUploader',
                  'brandHeadshots',
                  'brand_headshots',
                  true
                )
              "
            />
            <span class="small-img-container__upload-text">Upload</span>
          </div>
        </div>
        <div class="container-item__small-img business-assets">
          <div class="logo-title">Business assets</div>
          <div class="business-assets__list">
            <div
              class="business-assets__item"
              v-for="(
                businessAsset, businessAssetIndex
              ) in getCurrentSmbCustomer.business_assets.filter(
                (item, index) => index < 5
              )"
              :key="businessAsset + Math.random()"
            >
              <span
                class="icon-edit-png"
                @click="
                  clickedOnEditBusinessAssetHandler(
                    businessAsset,
                    businessAssetIndex
                  )
                "
              >
                <img src="@/assets/img/icons/edit.png" alt="" />
              </span>
              <span
                class="icon-remove-png"
                @click="deleteBusinessAssets(businessAssetIndex)"
                ><img src="@/assets/img/icons/remove.png" alt=""
              /></span>
              <div class="small-img-container">
                <img :src="businessAsset" :alt="businessAsset" />
              </div>
            </div>
            <div
              v-show="getCurrentSmbCustomer.business_assets.length < 5"
              class="small-img-container__upload"
            >
              <SmbFileUploader
                elementId="businessAssetsUploader"
                isHideInput
                custom-class=""
                prependIcon="icon-upload-image"
                acceptProp="image/png, image/jpeg, image/bmp"
                @filesWasUpdated="uploadBusinessAsset($event)"
              />
              <span class="small-img-container__upload-text">Upload</span>
            </div>
          </div>
        </div>
        <div class="logo-container-list__add-more">
          <v-icon
            @click="$router.push({ name: 'profile-settings-business-assets' })"
            class="edit-info"
            >mdi-chevron-right
          </v-icon>
        </div>
      </div>
      <div v-if="isShowColorsBlock" class="container-item__title">Color</div>
      <div
        class="mb-4"
        v-if="
          isShowColorsBlock && getCurrentSmbCustomer.brand_color_palettes.length
        "
      >
        <div class="squares-container">
          <div
            class="squares-container__item"
            v-for="(color, index) in getCurrentSmbCustomer
              .brand_color_palettes[0]"
            :key="index"
          >
            <div class="squares-container__item-controls">
              <span
                class="icon-edit-png"
                @click="
                  $router.push({
                    name: 'profile-settings-colors',
                    params: { changeIndex: index },
                  })
                "
              >
                <img src="@/assets/img/icons/edit.png" alt="" />
              </span>
              <i
                class="icon-remove"
                v-if="isColorCanDelete(color)"
                @click="deleteColorHandler(index)"
              ></i>
            </div>
            <div
              class="square"
              :style="{
                backgroundColor: color,
              }"
            ></div>
          </div>
          <div
            class="square__add"
            @click="
              $router.push({
                name: 'profile-settings-colors',
                params: {
                  ...{
                    isFromBrandingPage: $route.name === 'branding-settings',
                  },
                  ...$route.params,
                },
              })
            "
            v-if="getCurrentSmbCustomer.brand_color_palettes[0].length <= 5"
          >
            +
          </div>
        </div>
      </div>
      <div class="values" v-if="isShowValuesBlock">
        <div class="values__title">
          Values
          <div class="values__amount">
            ({{ getCurrentSmbCustomer.brand_values.length }}/3)
          </div>
        </div>
        <div class="values__list">
          <div class="values__list-add-more">
            <v-icon
              @click="$router.push({ name: 'settings-values' })"
              class="edit-info"
              >mdi-chevron-right
            </v-icon>
          </div>
          <div
            class="values__list-item"
            @click="$router.push({ name: 'settings-values' })"
            v-for="(value, index) in getCurrentSmbCustomer.brand_values"
            :key="index"
          >
            {{ value
            }}{{
              getCurrentSmbCustomer.brand_values.length !== index + 1
                ? ", "
                : ""
            }}
          </div>
        </div>
      </div>
      <div v-if="isShowFontsBlock" class="container-item__title">Fonts</div>
      <div v-if="isShowFontsBlock">
        <div class="fonts-container">
          <div class="fonts-container__block">
            <div class="logo-title">Main font:</div>
            <div
              class="fonts-container__item"
              v-if="getCurrentSmbCustomer.brand_font_families.length"
            >
              <div
                class="font"
                :style="{
                  fontFamily: getCurrentSmbCustomer.brand_font_families[0],
                }"
              >
                {{ getCurrentSmbCustomer.brand_font_families[0] }}
              </div>
              <div class="fonts-container__item-controls">
                <i
                  class="icon-edit"
                  @click="
                    clickOnFontIcoHandler(
                      true,
                      0,
                      getCurrentSmbCustomer.brand_font_families[0]
                    )
                  "
                ></i>
                <i class="icon-remove" @click="deleteFontHandler(0)"></i>
              </div>
            </div>
            <div
              class="fonts-container__add-font"
              @click="clickOnFontIcoHandler(false, 0, '')"
              v-else
            >
              + Add font
            </div>
          </div>
          <div class="fonts-container__block">
            <div class="logo-title">Secondary font:</div>
            <div
              class="fonts-container__item"
              v-if="getCurrentSmbCustomer.brand_font_families.length > 1"
            >
              <div
                class="font"
                :style="{
                  fontFamily: getCurrentSmbCustomer.brand_font_families[1],
                }"
              >
                {{ getCurrentSmbCustomer.brand_font_families[1] }}
              </div>
              <div class="fonts-container__item-controls">
                <i
                  class="icon-edit"
                  @click="
                    clickOnFontIcoHandler(
                      true,
                      1,
                      getCurrentSmbCustomer.brand_font_families[1]
                    )
                  "
                ></i>
                <i class="icon-remove" @click="deleteFontHandler(1)"></i>
              </div>
            </div>
            <div
              class="fonts-container__add-font"
              @click="clickOnFontIcoHandler(false, 1, 'Open Sans')"
              v-else
            >
              + Add font
            </div>
          </div>
        </div>
      </div>
      <div v-if="isShowHashtagsBlock" class="hashtags">
        <div class="hashtags__title">
          Hashtags
          <span class="hashtags__amount"
            >({{ getCurrentSmbCustomer.hashtags_constant.length }}/30)</span
          >
        </div>
        <div v-if="isShowHashtagsBlock" class="hashtags__list">
          <div class="hashtags__list-add-more">
            <v-icon
              @click="$router.push({ name: 'settings-hashtags' })"
              class="edit-info"
              >mdi-chevron-right
            </v-icon>
          </div>
          <span
            class="hashtags__list-item"
            v-for="(item, index) of getCurrentSmbCustomer.hashtags_constant"
            :key="index"
            @click="$router.push({ name: 'settings-hashtags' })"
            >{{ item }}</span
          >
        </div>
      </div>
      <ModalDialog
        :isShow="fontDialogIsShow"
        customClass="edit-font"
        :title="fontPopUpTitle"
        :footerButtons="fontPopUpBtn"
        :isLoading="isLoadingPopUp"
        @save="saveFontHandler"
        @close="closeFontPopUpHandler"
      >
        <template v-slot:description
          ><div class="choose-font-pop-up">
            <span
              class="apply-font"
              :style="{
                fontFamily: fontValue,
              }"
              >This is text with selected font</span
            >
            <div class="choose-font-select">
              <div class="select-wrapper">
                <span> Sort by: </span>
                <select
                  class="select"
                  name="sortBy"
                  id="select"
                  v-model="sortBy"
                >
                  <option value="alpha">Alphabetically</option>
                  <option value="popularity">Popularity</option>
                  <option value="trending">Trending</option>
                </select>
              </div>
              <v-select
                v-model="fontValue"
                :items="fontsListWithLimit"
                label="Fonts"
                item-text="family"
                item-value="family"
              >
                <template v-slot:item="{ item }">
                  <div
                    :style="{
                      fontFamily: item.family,
                    }"
                  >
                    {{ item.family }}
                  </div>
                </template>
                <template v-slot:selection="{ item }">
                  <span
                    :style="{
                      fontFamily: item.family,
                    }"
                    >{{ item.family }}</span
                  >
                </template>
                <template v-slot:append-item>
                  <div v-intersect="endIntersect" />
                </template>
              </v-select>
            </div></div
        ></template>
      </ModalDialog>
      <ModalDialog
        :isShow="removeBgDialog.isShow"
        :title="removeBgDialog.title"
        :footerButtons="removeBgDialog.footerButtons"
        :isLoading="removeBgDialog.isLoading"
        :isClickOutside="false"
        :isShowSlotFooter="false"
        customClass="remove-bg-dialog"
        @success="approveToRemoveBg"
        @cancel="canceledRemoveBg"
        @close="closeRemoveBgHandler"
        @acceptToSetNewImg="acceptToSetNewImgHandler"
        @cancelToUpdate="cancelToUpdate"
        @successToRemoveBusinessAsset="successToRemoveBusinessAssetHandler"
        @cancelToRemoveBgForBusinessAsset="
          cancelToRemoveBgForBusinessAssetHandler
        "
        @acceptToSetBusinessAsset="acceptToSetBusinessAssetHandler"
      >
        <template v-slot:description v-if="removeBgDialog.isShowImgWithoutBg">
          <div class="img-wrapper">
            <img
              class="img-without-bg"
              :src="imgUrlWithoutBg"
              :alt="imgUrlWithoutBg"
            />
          </div>
        </template>
      </ModalDialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

import uploader from "@/mixins/uploader";
import Loader from "@/components/global/Loader.vue";
import SmbFileUploader from "@/components/smb-client/SmbFileUploader";
import ModalDialog from "@/components/global/ModalDialog.vue";
import { TEMPLATE_FONTS } from "@/constants/globals/templateFonts";
export default {
  name: "SmbBranding",
  data: () => ({
    isLoading: false,
    isShowBrandLogoUploader: false,
    brandLogo: null,
    isShowBrokerageLogoUploader: false,
    brokerageLogo: null,
    isShowHeadshotUploader: false,
    brandHeadshots: null,
    sortBy: "alpha",
    fontDialogIsShow: false,
    isLoadingPopUp: false,
    fontValue: "Open Sans",
    fontsListWithLimit: [],
    fullFontList: [],
    limitFontCount: 10,
    loadedFonts: [],
    googleFontKey: "AIzaSyASjCNFecBp9iwbhGFjkkKajjBHOUs61iw",
    supportedFontFamilies: TEMPLATE_FONTS,
    isEditFont: false,
    fontIndex: 0,
    removeBgDialog: {
      isShow: false,
      title: "Would you like to remove the background?",
      isLoading: false,
      isShowImgWithoutBg: false,
      footerButtons: [
        { name: "No", emitName: "cancel", btnClass: "black-border" },
        { name: "Yes", emitName: "success", btnClass: "black" },
      ],
    },
    currentImgNameForEdit: null,
    imgUrlWithoutBg: null,
    dataForCurrentImgForEdit: {
      showVariable: null,
      fileVariable: null,
      backendName: null,
      isValueArray: false,
    },
    currentBusinessAssetToEdit: {
      url: null,
      index: null,
    },
  }),
  mixins: [uploader],
  watch: {
    sortBy() {
      this.fetchGoogleFontsList();
    },
  },
  props: {
    isShowImagesBlock: {
      type: Boolean,
      default: true,
    },
    isShowColorsBlock: {
      type: Boolean,
      default: true,
    },
    isShowFontsBlock: {
      type: Boolean,
      default: true,
    },
    isShowHashtagsBlock: {
      type: Boolean,
      default: true,
    },
    isShowValuesBlock: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Loader,
    SmbFileUploader,
    ModalDialog,
  },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    fontPopUpTitle() {
      return `${this.isEditFont ? "Edit" : "Choose"} a font`;
    },
    fontPopUpBtn() {
      return [
        {
          name: this.isEditFont ? "Change" : "Save",
          emitName: "save",
          btnClass: "black",
        },
        { name: "Cancel", emitName: "close", btnClass: "black-border" },
      ];
    },
  },
  async created() {
    if (!this.getCurrentSmbCustomer.brand_color_palettes.length) {
      this.isLoading = true;
      await this.updateCustomer({
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          brand_color_palettes: [["#000000", "#FFFFFF"]],
        },
      });
      this.isLoading = false;
    }

    this.getCurrentSmbCustomer.brand_font_families.forEach((font) =>
      this.loadNewFonts(font)
    );
  },
  methods: {
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapActions("removeBg", ["deleteBgForImageByUrl"]),
    ...mapActions("mediaLibraryWidget", ["uploadMediaFromUrl"]),
    async deleteImage(backendName, isValueArray = false) {
      this.isLoading = true;
      let params = {
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          [backendName]: isValueArray ? [] : null,
        },
      };
      await this.updateCustomer(params);
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      this.isLoading = false;
    },
    closeUploader(showVariable, fileVariable) {
      this.$data[showVariable] = false;
      this.$data[fileVariable] = null;
    },
    async uploadSelected(
      event,
      showVariable,
      fileVariable,
      backendName,
      isValueArray = false
    ) {
      this.isLoading = true;
      const { url, fields } = await this.uploadFileHandler(
        [...[event]],
        this.getCurrentSmbCustomer.id
      );

      await this.updateMediaId(
        url + fields.key,
        fileVariable,
        backendName,
        isValueArray
      );
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      this.closeUploader(showVariable, fileVariable);
      this.isLoading = false;
    },
    async updateMediaId(url, fieldName, backendName, isValueArray = false) {
      let params = {
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          [backendName]: isValueArray ? [url] : url,
        },
      };
      await this.updateCustomer(params);
    },
    isColorCanDelete(color) {
      const notAllowedColorsForDelete = ["#000000", "#FFFFFF"];
      return !notAllowedColorsForDelete.includes(color);
    },
    async deleteColorHandler(colorIndex) {
      this.isLoading = true;
      let params = {
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          brand_color_palettes:
            this.getCurrentSmbCustomer.brand_color_palettes.with(
              0,
              this.getCurrentSmbCustomer.brand_color_palettes[0].filter(
                (color, index) => index !== colorIndex
              )
            ),
        },
      };
      await this.updateCustomer(params);
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      this.isLoading = false;
    },
    async clickOnFontIcoHandler(isEditFont = false, fontIndex = 0, font = "") {
      this.isEditFont = isEditFont;
      this.fontIndex = fontIndex;
      this.fontValue = font;

      try {
        await this.fetchGoogleFontsList();
      } catch (e) {
        throw e;
      }

      this.fontDialogIsShow = true;
    },
    async fetchGoogleFontsList() {
      const { data } = await axios.get(
        `https://www.googleapis.com/webfonts/v1/webfonts?sort=${this.sortBy}&key=${this.googleFontKey}`
      );

      this.fullFontList = data.items.filter(({ family }) =>
        this.supportedFontFamilies.includes(family)
      );

      await this.setFontList();
    },
    async setFontList() {
      this.fontsListWithLimit = this.fullFontList.filter(
        (item, index) => index <= this.limitFontCount
      );

      if (!this.fontsListWithLimit.includes(this.fontValue)) {
        this.fontsListWithLimit = [
          ...this.fontsListWithLimit,
          ...[{ family: this.fontValue }],
        ];

        this.limitFontCount += 1;
      }

      for (const { family } of this.fontsListWithLimit) {
        await this.loadNewFonts(family);
      }
    },
    loadNewFonts(name) {
      if (!this.loadedFonts.includes(name)) {
        const link = document.createElement("link");
        link.href = `https://fonts.googleapis.com/css2?family=${name}`;
        link.rel = "stylesheet";
        document.head.appendChild(link);
        this.loadedFonts.push(name);
      }
    },
    async deleteFontHandler(fontIndex) {
      try {
        let params = {
          customerId: this.getCurrentSmbCustomer.id,
          params: {
            brand_font_families:
              this.getCurrentSmbCustomer.brand_font_families.filter(
                (font, index) => fontIndex !== index
              ),
          },
        };
        this.isLoading = true;
        await this.updateCustomer(params);
        await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
        this.isLoading = false;
      } catch (e) {
        throw e;
      }
    },
    async saveFontHandler() {
      let indexForSet =
        !this.isEditFont &&
        !this.getCurrentSmbCustomer.brand_font_families.length
          ? this.getCurrentSmbCustomer.brand_font_families.length
          : this.fontIndex;

      let newFontsFamilies = [
        ...this.getCurrentSmbCustomer.brand_font_families,
      ];
      newFontsFamilies[indexForSet] = this.fontValue;

      let params = {
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          brand_font_families: newFontsFamilies,
        },
      };
      try {
        this.isLoadingPopUp = true;
        await this.updateCustomer(params);
        await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
        this.fontDialogIsShow = false;
        this.isEditFont = false;
        this.isLoadingPopUp = false;
        this.fontValue = "Open Sans";
      } catch (e) {
        throw e;
      }
    },
    closeFontPopUpHandler() {
      this.fontDialogIsShow = false;
      this.fontValue = "Open Sans";
    },
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.limitFontCount += 10;
        this.setFontList();
      }
    },
    emitClickOnUploaderByElementId(id) {
      setTimeout(() => {
        document.getElementById(id).click();
      }, 0);
    },
    clickOnEditImgIcon(imgName) {
      this.removeBgDialog.isShow = true;
      this.currentImgNameForEdit = imgName;
    },
    async approveToRemoveBg() {
      let imgUrl;

      this.removeBgDialog.isLoading = true;

      switch (this.currentImgNameForEdit) {
        case "brandLogo":
          imgUrl = this.getCurrentSmbCustomer.brand_logo;
          this.dataForCurrentImgForEdit = {
            showVariable: "isShowBrandLogoUploader",
            fileVariable: "brandLogo",
            backendName: "brand_logo",
            isValueArray: false,
          };
          break;
        case "brokerageLogo":
          imgUrl = this.getCurrentSmbCustomer.brokerage_logo;
          this.dataForCurrentImgForEdit = {
            showVariable: "isShowBrokerageLogoUploader",
            fileVariable: "brokerageLogo",
            backendName: "brokerage_logo",
            isValueArray: false,
          };
          break;
        case "brandHeadshots":
          imgUrl = this.getCurrentSmbCustomer.brand_headshots;
          this.dataForCurrentImgForEdit = {
            showVariable: "isShowHeadshotUploader",
            fileVariable: "brandHeadshots",
            backendName: "brand_headshots",
            isValueArray: true,
          };
          break;
        default:
          imgUrl = "";
          break;
      }

      if (imgUrl) {
        try {
          const { image_url, error } = await this.deleteBgForImageByUrl(imgUrl);

          if (!error) {
            this.imgUrlWithoutBg = image_url;
            this.removeBgDialog.isShowImgWithoutBg = true;

            this.removeBgDialog = {
              ...this.removeBgDialog,
              ...{
                title: `Set this image as your new ${
                  this.currentImgNameForEdit === "brandLogo"
                    ? "logo"
                    : this.currentImgNameForEdit === "brokerageLogo"
                    ? "brokerage"
                    : "headshots"
                }?`,
                footerButtons: [
                  {
                    name: "No",
                    emitName: "cancelToUpdate",
                    btnClass: "black-border",
                  },
                  {
                    name: "Yes",
                    emitName: "acceptToSetNewImg",
                    btnClass: "black",
                  },
                ],
              },
            };

            this.removeBgDialog.isLoading = false;
          } else {
            this.somethingWentWrongWhenDeletedBackgroundHandler();
          }
        } catch (e) {
          this.somethingWentWrongWhenDeletedBackgroundHandler();
        }
      }
    },
    somethingWentWrongWhenDeletedBackgroundHandler() {
      this.removeBgDialog = {
        ...this.removeBgDialog,
        ...{
          title: "Something went wrong...",
          footerButtons: [
            { name: "Ok", emitName: "cancel", btnClass: "black" },
          ],
          isShowImgWithoutBg: false,
        },
      };
      this.removeBgDialog.isLoading = false;
    },
    cancelToUpdate() {
      this.removeBgDialog.isLoading = true;
      this.makeDefaultDialogOptions();

      this.dataForCurrentImgForEdit = {
        showVariable: null,
        fileVariable: null,
        backendName: null,
        isValueArray: false,
      };
    },
    closeRemoveBgHandler() {
      this.makeDefaultDialogOptions();

      this.currentBusinessAssetToEdit = {
        url: null,
        index: null,
      };
    },
    canceledRemoveBg() {
      this.removeBgDialog.isLoading = true;
      this.makeDefaultDialogOptions();

      this.dataForCurrentImgForEdit = {
        showVariable: null,
        fileVariable: null,
        backendName: null,
        isValueArray: false,
      };

      switch (this.currentImgNameForEdit) {
        case "brandLogo":
          this.isShowBrandLogoUploader = true;
          break;
        case "brokerageLogo":
          this.isShowBrokerageLogoUploader = true;
          break;
        case "brandHeadshots":
          this.isShowHeadshotUploader = true;
          break;
        default:
          return 0;
      }

      this.emitClickOnUploaderByElementId(this.currentImgNameForEdit);
    },
    makeDefaultDialogOptions() {
      this.removeBgDialog = {
        isShow: false,
        title: "Would you like to remove the background?",
        isLoading: false,
        isShowImgWithoutBg: false,
        footerButtons: [
          { name: "No", emitName: "cancel", btnClass: "black-border" },
          { name: "Yes", emitName: "success", btnClass: "black" },
        ],
      };
    },
    async acceptToSetNewImgHandler() {
      this.removeBgDialog.isShow = false;
      this.isLoading = true;

      const { filename, extension } = this.extractFilenameAndType(
        this.imgUrlWithoutBg
      );

      const {
        data: { media_url },
      } = await this.uploadMediaFromUrl({
        customerId: this.getCurrentSmbCustomer.id,
        inputMediaUrl: this.imgUrlWithoutBg,
        createdBy: "6",
        isLicensed: false,
        processMedia: false,
        createMediaItem: false,
        fileName: `${filename}_bg.${extension}`,
      });

      let { fileVariable, backendName, isValueArray, showVariable } =
        this.dataForCurrentImgForEdit;

      await this.updateMediaId(
        media_url,
        fileVariable,
        backendName,
        isValueArray
      );

      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);

      this.closeUploader(showVariable, fileVariable);

      this.makeDefaultDialogOptions();

      this.dataForCurrentImgForEdit = {
        showVariable: null,
        fileVariable: null,
        backendName: null,
        isValueArray: false,
      };

      this.isLoading = false;
    },
    extractFilenameAndType(url) {
      const parts = url.split("/");
      const filenameWithExtension = parts[parts.length - 1];

      const filenameParts = filenameWithExtension.split(".");
      const filename = filenameParts.slice(0, -1).join(".");
      const extension = filenameParts[filenameParts.length - 1];

      return { filename, extension };
    },
    async uploadBusinessAsset(file) {
      this.isLoading = true;
      const { url, fields } = await this.uploadFileHandler(
        [...[file]],
        this.getCurrentSmbCustomer.id
      );

      const assetUrl = url + fields.key;

      const newAssetsList = this.currentBusinessAssetToEdit.url
        ? this.getCurrentSmbCustomer.business_assets.map(
            (businessAsset, businessAssetIndex) => {
              if (
                businessAssetIndex === this.currentBusinessAssetToEdit.index
              ) {
                businessAsset = assetUrl;
              }
              return businessAsset;
            }
          )
        : [...this.getCurrentSmbCustomer.business_assets, ...[assetUrl]];

      const params = {
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          business_assets: newAssetsList,
        },
      };

      await this.updateCustomer(params);

      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);

      this.currentBusinessAssetToEdit = {
        url: null,
        index: null,
      };
      this.isLoading = false;
    },
    clickedOnEditBusinessAssetHandler(assetUrl, assetIndex) {
      this.removeBgDialog = {
        isShow: false,
        title: "Would you like to remove the background?",
        isLoading: false,
        isShowImgWithoutBg: false,
        footerButtons: [
          {
            name: "No",
            emitName: "cancelToRemoveBgForBusinessAsset",
            btnClass: "black-border",
          },
          {
            name: "Yes",
            emitName: "successToRemoveBusinessAsset",
            btnClass: "black",
          },
        ],
      };

      this.currentBusinessAssetToEdit = {
        url: assetUrl,
        index: assetIndex,
      };

      this.removeBgDialog.isShow = true;
    },
    async successToRemoveBusinessAssetHandler() {
      this.removeBgDialog.isLoading = true;

      try {
        const { image_url, error } = await this.deleteBgForImageByUrl(
          this.currentBusinessAssetToEdit.url
        );

        if (!error) {
          this.imgUrlWithoutBg = image_url;
          this.removeBgDialog.isShowImgWithoutBg = true;

          this.removeBgDialog = {
            ...this.removeBgDialog,
            ...{
              title: `Set this image as business asset?`,
              footerButtons: [
                {
                  name: "No",
                  emitName: "cancelToUpdate",
                  btnClass: "black-border",
                },
                {
                  name: "Yes",
                  emitName: "acceptToSetBusinessAsset",
                  btnClass: "black",
                },
              ],
            },
          };

          this.removeBgDialog.isLoading = false;
        } else {
          this.somethingWentWrongWhenDeletedBackgroundHandler();
        }
      } catch (e) {
        console.log(e);
        this.somethingWentWrongWhenDeletedBackgroundHandler();
      }
    },
    async acceptToSetBusinessAssetHandler() {
      const businessAssetsList = this.getCurrentSmbCustomer.business_assets.map(
        (businessAsset, businessAssetIndex) => {
          if (businessAssetIndex === this.currentBusinessAssetToEdit.index) {
            businessAsset = this.imgUrlWithoutBg;
          }
          return businessAsset;
        }
      );

      const params = {
        customerId: this.getCurrentSmbCustomer.id,
        params: {
          business_assets: businessAssetsList,
        },
      };
      await this.updateCustomer(params);
      await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      this.closeRemoveBgHandler();
    },
    cancelToRemoveBgForBusinessAssetHandler() {
      this.emitClickOnUploaderByElementId("businessAssetsUploader");
      this.makeDefaultDialogOptions();
    },
    async deleteBusinessAssets(indexForDelete) {
      this.isLoading = true;

      try {
        const params = {
          customerId: this.getCurrentSmbCustomer.id,
          params: {
            business_assets: this.getCurrentSmbCustomer.business_assets.filter(
              (businessAsset, businessAssetIndex) =>
                businessAssetIndex !== indexForDelete
            ),
          },
        };
        await this.updateCustomer(params);
        await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
      } catch (e) {
        throw e;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.smb-client-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f2f3;
  height: 100%;
  .buttons-wrapper {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 10px 20px;
    flex: 1;
  }
  .support-link {
    margin-bottom: 10px;
  }
  .client-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    &__wrapper {
      width: 100%;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0 24px;
    }

    .user {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 8px;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid rgb(47, 72, 88, 0.1);

      &__photo {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 24px;
        flex-shrink: 0;
        margin-top: 5px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__info {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      &__name {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 8px;
      }

      &__contacts {
        width: 100%;
        padding: 8px 12px;
        background: rgba(78, 87, 120, 0.05);
        border: 1px solid rgba(47, 72, 88, 0.05);
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        flex-direction: column;

        &-row {
          display: inline-flex;
          align-items: center;
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }

          span {
            font-weight: normal;
            font-size: 12px;
            line-height: 140%;
          }

          i {
            font-size: 12px;
            line-height: 140%;
            margin-right: 10px;
          }
        }
      }
    }
    .social {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 15px;
      &__item {
        margin-right: 12px;
        position: relative;
        &-actions {
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          z-index: 5;
          .edit-btn {
            font-size: 12px;
            cursor: pointer;
            text-decoration: none;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
      &__item-link {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: 1px solid #eeeff0;
        box-sizing: border-box;
        box-shadow: 0px 2px 12px rgba(31, 35, 37, 0.06);
        border-radius: 50%;
        position: relative;
      }
      &__btn-connect {
        padding: 0 12px;
        text-transform: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 140%;
        color: #1d1d1b;
        box-shadow: none;
        text-indent: inherit;
        letter-spacing: inherit;
        background: #f5ede2;
        border: 1px solid #1d1d1b;
        border-radius: 16px;
        margin-right: 13px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .logo-container-list {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      &__add-more {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
    .container-item {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid rgb(47, 72, 88, 0.1);
      &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 12px;
      }
      &__small-img {
        width: 100%;
        max-width: 60px;
        margin-right: 10px;
        .logo-title {
          font-weight: 400;
          font-size: 10px;
          margin-bottom: 12px;
          text-align: center;
        }
        .small-img-container {
          position: relative;
          width: 60px;
          height: 60px;
          cursor: pointer;
          &__upload {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            ::v-deep {
              .v-input__prepend-outer {
                margin-top: 0;
                margin-bottom: 0;
                margin-right: 0;
                .icon {
                  width: 40px;
                  height: 50px;
                  flex-shrink: 0;
                  &:after {
                    display: none;
                  }
                }
              }
            }
            &-text {
              font-weight: 500;
              font-size: 10px;
              line-height: 140%;
              text-decoration: underline;
            }
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          i {
            cursor: pointer;
            position: absolute;
            padding: 2px;
            border-radius: 50%;
            display: inline-flex;
            font-size: 12px;
            &.icon-edit {
              left: 0;
            }
            &.icon-remove {
              right: 0;
            }
          }
          .action-button {
            width: 17px;
            height: 17px;
            display: inline-flex;
            position: absolute;
            left: 50%;
            top: 50%;
            i {
              color: white;
            }

            transform: translate(-50%, -50%) scale(0);
            transition: all 0.3s ease-in;
          }
          .icon-edit-png {
            width: 17px;
            height: 17px;
            display: inline-flex;
            position: absolute;
            left: 0;
          }
          &:hover {
            .action-button {
              transform: translate(-50%, -50%) scale(1);
            }
          }
          .icon-remove-png {
            width: 17px;
            height: 17px;
            display: inline-flex;
            position: absolute;
            right: 0;
          }
        }
      }
      .business-assets {
        max-width: none;
        width: initial;
        padding-left: 0;
        padding-right: 0;
        @media screen and (max-width: 600px) {
          width: 100%;
          margin-left: 0 !important;
          margin-top: 10px;
          padding-left: 0 !important;
        }
        &__list {
          display: flex;
          align-items: center;
          position: relative;
          .small-img-container__upload {
            margin: 0 10px;
          }
        }
        &__item {
          margin-right: 5px;
          position: relative;
          &:last-child {
            margin-right: 0;
          }
          .icon-remove-png {
            width: 17px;
            height: 17px;
            display: inline-flex;
            position: absolute;
            right: 0;
            z-index: 10;
            cursor: pointer;
          }
          .icon-edit-png {
            width: 17px;
            height: 17px;
            display: inline-flex;
            position: absolute;
            left: 0;
            z-index: 10;
            cursor: pointer;
          }
        }
        .logo-title {
          text-align: left;
          min-width: initial;
        }
      }
    }
    .company-info {
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        margin: 0 0 8px 0;
        align-items: center;
        flex-wrap: nowrap;
        &:last-child {
          margin-bottom: 0;
        }
        .title {
          font-weight: 500;
          font-size: 12px !important;
          line-height: 140%;
          color: #4e5778;
          width: 90px;
          flex-shrink: 0;
          margin-right: 20px;
        }
        .data {
          font-weight: normal;
          font-size: 14px;
          line-height: 160%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &.pricing {
            color: $color-green;
          }
        }
      }
    }
    .edit-info {
      margin-left: auto;
      cursor: pointer;
    }
    .squares-container {
      display: flex;
      flex-direction: row;
      &__item {
        display: inline-flex;
        margin-right: 10px;
        position: relative;
        &-controls {
          padding: 2px 2px 0 2px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          i {
            font-size: 12px;
            cursor: pointer;
          }
          .icon-edit-png {
            width: 17px;
            height: 17px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .fonts-container {
      display: flex;
      flex-direction: column;
      &__block {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
      }
      .logo-title {
        font-weight: 500;
        font-size: 12px;
      }
      &__item {
        display: inline-flex;
        position: relative;
        &-controls {
          display: flex;
          align-items: center;
          i {
            font-size: 12px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
      &__add {
        width: 40px;
        height: 40px;
        border: 1px dashed #000;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 5px;
        font-size: 15px;
      }
      &__add-font {
        padding: 2px 9px;
        border-radius: 15px;
        background: #000;
        color: #fff;
        cursor: pointer;
        margin-left: 7px;
        font-weight: 500;
        font-size: 12px;
      }
      .font {
        width: 100%;
        font-size: 12px;
        margin-right: 3px;
        margin-left: 7px;
      }
    }
  }
  &__footer {
    @media screen and (min-width: 760px) {
      display: none;
    }
  }
}

.square {
  width: 40px;
  height: 40px;
  &__add {
    width: 40px;
    height: 40px;
    border: 1px dashed #000;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    font-size: 15px;
  }
}

.choose-font-pop-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .apply-font {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .select-wrapper {
    .select {
      padding: 2px 21px 2px 10px;
      outline: none;
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
      background-repeat: no-repeat;
      background-position: right 5px top 50%;
      background-size: 7px auto;
      cursor: pointer;
      border-bottom: 1px solid #908f8f;
    }
  }
  .choose-font-select {
    width: 100%;
  }
  .font-picker {
    .expanded {
      max-height: 170px;
    }
  }
}

.tips {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #1f2325;
    margin-bottom: 10px;
  }
  &__buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 10px 5px;
  }
}
.values {
  display: flex;
  flex-direction: column;
  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 12px;
    margin-top: 5px;
  }
  &__amount {
    display: inline-flex;
    font-size: 11px;
    font-weight: 400;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-right: 40px;
    margin-bottom: 10px;
    &-add-more {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    &-item {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-right: 4px;
      cursor: pointer;
      margin-bottom: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.hashtags {
  display: flex;
  flex-direction: column;
  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 12px;
    margin-top: 5px;
  }
  &__amount {
    font-size: 11px;
    font-weight: 400;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-right: 40px;
    &-add-more {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    &-item {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      display: flex;
      align-items: center;
      color: #00376b;
      margin-right: 4px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.mb-4 {
  margin-bottom: 20px;
}
.fixed {
  position: fixed;
}
</style>
