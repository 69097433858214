var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smb-client-wrapper"},[(_vm.isLoading)?_c('Loader'):_vm._e(),_c('div',{staticClass:"client-info-container"},[_c('div',{staticClass:"container-item__title"},[_vm._v("Branding")]),(_vm.isShowImagesBlock)?_c('div',{staticClass:"logo-container-list"},[_c('div',{staticClass:"container-item__small-img"},[_c('div',{staticClass:"logo-title"},[_vm._v("Logo")]),(_vm.getCurrentSmbCustomer.brand_logo && !_vm.isShowBrandLogoUploader)?_c('div',{staticClass:"small-img-container"},[_c('span',{staticClass:"icon-edit-png",on:{"click":function($event){return _vm.clickOnEditImgIcon('brandLogo')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/edit.png"),"alt":""}})]),_c('span',{staticClass:"icon-remove-png",on:{"click":function($event){return _vm.deleteImage('brand_logo')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/remove.png"),"alt":""}})]),_c('img',{attrs:{"src":_vm.getCurrentSmbCustomer.brand_logo,"alt":_vm.getCurrentSmbCustomer.brand_logo}})]):_c('div',{staticClass:"small-img-container__upload"},[_c('SmbFileUploader',{attrs:{"elementId":"brandLogo","filesProp":_vm.brandLogo,"isHideInput":"","prependIcon":"icon-upload-image","acceptProp":"image/png, image/jpeg, image/bmp*"},on:{"dialogCanceledWithoutSelectingFiles":function($event){_vm.isShowBrandLogoUploader = false},"filesWasUpdated":function($event){return _vm.uploadSelected(
                $event,
                'isShowBrandLogoUploader',
                'brandLogo',
                'brand_logo'
              )}}}),_c('span',{staticClass:"small-img-container__upload-text"},[_vm._v("Upload")])],1)]),_c('div',{staticClass:"container-item__small-img pl-4"},[_c('div',{staticClass:"logo-title"},[_vm._v("Brokerage")]),(
            _vm.getCurrentSmbCustomer.brokerage_logo &&
            !_vm.isShowBrokerageLogoUploader
          )?_c('div',{staticClass:"small-img-container"},[_c('span',{staticClass:"icon-edit-png",on:{"click":function($event){return _vm.clickOnEditImgIcon('brokerageLogo')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/edit.png"),"alt":""}})]),_c('span',{staticClass:"icon-remove-png",on:{"click":function($event){return _vm.deleteImage('brokerage_logo')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/remove.png"),"alt":""}})]),_c('img',{attrs:{"src":_vm.getCurrentSmbCustomer.brokerage_logo,"alt":_vm.getCurrentSmbCustomer.brokerage_logo}})]):_c('div',{staticClass:"small-img-container__upload"},[_c('SmbFileUploader',{attrs:{"elementId":"brokerageLogo","filesProp":_vm.brokerageLogo,"isHideInput":"","prependIcon":"icon-upload-image","acceptProp":"image/png, image/jpeg, image/bmp"},on:{"dialogCanceledWithoutSelectingFiles":function($event){_vm.isShowBrokerageLogoUploader = false},"filesWasUpdated":function($event){return _vm.uploadSelected(
                $event,
                'isShowBrokerageLogoUploader',
                'brokerageLogo',
                'brokerage_logo'
              )}}}),_c('span',{staticClass:"small-img-container__upload-text"},[_vm._v("Upload")])],1)]),_c('div',{staticClass:"container-item__small-img pl-4 mr-5"},[_c('div',{staticClass:"logo-title"},[_vm._v("Headshot")]),(
            _vm.getCurrentSmbCustomer.brand_headshots &&
            _vm.getCurrentSmbCustomer.brand_headshots.length &&
            !_vm.isShowHeadshotUploader
          )?_c('div',{staticClass:"small-img-container"},[_c('span',{staticClass:"icon-edit-png",on:{"click":function($event){return _vm.clickOnEditImgIcon('brandHeadshots')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/edit.png"),"alt":""}})]),_c('span',{staticClass:"icon-remove-png",on:{"click":function($event){return _vm.deleteImage('brand_headshots', true)}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/remove.png"),"alt":""}})]),_c('img',{attrs:{"src":_vm.getCurrentSmbCustomer.brand_headshots[0],"alt":_vm.getCurrentSmbCustomer.brand_headshots[0]}})]):_c('div',{staticClass:"small-img-container__upload"},[_c('SmbFileUploader',{attrs:{"elementId":"brandHeadshots","filesProp":_vm.brandHeadshots,"isHideInput":"","custom-class":"","prependIcon":"icon-upload-image","acceptProp":"image/png, image/jpeg, image/bmp"},on:{"dialogCanceledWithoutSelectingFiles":function($event){_vm.isShowHeadshotUploader = false},"filesWasUpdated":function($event){return _vm.uploadSelected(
                $event,
                'isShowHeadshotUploader',
                'brandHeadshots',
                'brand_headshots',
                true
              )}}}),_c('span',{staticClass:"small-img-container__upload-text"},[_vm._v("Upload")])],1)]),_c('div',{staticClass:"container-item__small-img business-assets"},[_c('div',{staticClass:"logo-title"},[_vm._v("Business assets")]),_c('div',{staticClass:"business-assets__list"},[_vm._l((_vm.getCurrentSmbCustomer.business_assets.filter(
              (item, index) => index < 5
            )),function(businessAsset,businessAssetIndex){return _c('div',{key:businessAsset + Math.random(),staticClass:"business-assets__item"},[_c('span',{staticClass:"icon-edit-png",on:{"click":function($event){return _vm.clickedOnEditBusinessAssetHandler(
                  businessAsset,
                  businessAssetIndex
                )}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/edit.png"),"alt":""}})]),_c('span',{staticClass:"icon-remove-png",on:{"click":function($event){return _vm.deleteBusinessAssets(businessAssetIndex)}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/remove.png"),"alt":""}})]),_c('div',{staticClass:"small-img-container"},[_c('img',{attrs:{"src":businessAsset,"alt":businessAsset}})])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getCurrentSmbCustomer.business_assets.length < 5),expression:"getCurrentSmbCustomer.business_assets.length < 5"}],staticClass:"small-img-container__upload"},[_c('SmbFileUploader',{attrs:{"elementId":"businessAssetsUploader","isHideInput":"","custom-class":"","prependIcon":"icon-upload-image","acceptProp":"image/png, image/jpeg, image/bmp"},on:{"filesWasUpdated":function($event){return _vm.uploadBusinessAsset($event)}}}),_c('span',{staticClass:"small-img-container__upload-text"},[_vm._v("Upload")])],1)],2)]),_c('div',{staticClass:"logo-container-list__add-more"},[_c('v-icon',{staticClass:"edit-info",on:{"click":function($event){return _vm.$router.push({ name: 'profile-settings-business-assets' })}}},[_vm._v("mdi-chevron-right ")])],1)]):_vm._e(),(_vm.isShowColorsBlock)?_c('div',{staticClass:"container-item__title"},[_vm._v("Color")]):_vm._e(),(
        _vm.isShowColorsBlock && _vm.getCurrentSmbCustomer.brand_color_palettes.length
      )?_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"squares-container"},[_vm._l((_vm.getCurrentSmbCustomer
            .brand_color_palettes[0]),function(color,index){return _c('div',{key:index,staticClass:"squares-container__item"},[_c('div',{staticClass:"squares-container__item-controls"},[_c('span',{staticClass:"icon-edit-png",on:{"click":function($event){return _vm.$router.push({
                  name: 'profile-settings-colors',
                  params: { changeIndex: index },
                })}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/edit.png"),"alt":""}})]),(_vm.isColorCanDelete(color))?_c('i',{staticClass:"icon-remove",on:{"click":function($event){return _vm.deleteColorHandler(index)}}}):_vm._e()]),_c('div',{staticClass:"square",style:({
              backgroundColor: color,
            })})])}),(_vm.getCurrentSmbCustomer.brand_color_palettes[0].length <= 5)?_c('div',{staticClass:"square__add",on:{"click":function($event){return _vm.$router.push({
              name: 'profile-settings-colors',
              params: {
                ...{
                  isFromBrandingPage: _vm.$route.name === 'branding-settings',
                },
                ..._vm.$route.params,
              },
            })}}},[_vm._v(" + ")]):_vm._e()],2)]):_vm._e(),(_vm.isShowValuesBlock)?_c('div',{staticClass:"values"},[_c('div',{staticClass:"values__title"},[_vm._v(" Values "),_c('div',{staticClass:"values__amount"},[_vm._v(" ("+_vm._s(_vm.getCurrentSmbCustomer.brand_values.length)+"/3) ")])]),_c('div',{staticClass:"values__list"},[_c('div',{staticClass:"values__list-add-more"},[_c('v-icon',{staticClass:"edit-info",on:{"click":function($event){return _vm.$router.push({ name: 'settings-values' })}}},[_vm._v("mdi-chevron-right ")])],1),_vm._l((_vm.getCurrentSmbCustomer.brand_values),function(value,index){return _c('div',{key:index,staticClass:"values__list-item",on:{"click":function($event){return _vm.$router.push({ name: 'settings-values' })}}},[_vm._v(" "+_vm._s(value)+_vm._s(_vm.getCurrentSmbCustomer.brand_values.length !== index + 1 ? ", " : "")+" ")])})],2)]):_vm._e(),(_vm.isShowFontsBlock)?_c('div',{staticClass:"container-item__title"},[_vm._v("Fonts")]):_vm._e(),(_vm.isShowFontsBlock)?_c('div',[_c('div',{staticClass:"fonts-container"},[_c('div',{staticClass:"fonts-container__block"},[_c('div',{staticClass:"logo-title"},[_vm._v("Main font:")]),(_vm.getCurrentSmbCustomer.brand_font_families.length)?_c('div',{staticClass:"fonts-container__item"},[_c('div',{staticClass:"font",style:({
                fontFamily: _vm.getCurrentSmbCustomer.brand_font_families[0],
              })},[_vm._v(" "+_vm._s(_vm.getCurrentSmbCustomer.brand_font_families[0])+" ")]),_c('div',{staticClass:"fonts-container__item-controls"},[_c('i',{staticClass:"icon-edit",on:{"click":function($event){return _vm.clickOnFontIcoHandler(
                    true,
                    0,
                    _vm.getCurrentSmbCustomer.brand_font_families[0]
                  )}}}),_c('i',{staticClass:"icon-remove",on:{"click":function($event){return _vm.deleteFontHandler(0)}}})])]):_c('div',{staticClass:"fonts-container__add-font",on:{"click":function($event){return _vm.clickOnFontIcoHandler(false, 0, '')}}},[_vm._v(" + Add font ")])]),_c('div',{staticClass:"fonts-container__block"},[_c('div',{staticClass:"logo-title"},[_vm._v("Secondary font:")]),(_vm.getCurrentSmbCustomer.brand_font_families.length > 1)?_c('div',{staticClass:"fonts-container__item"},[_c('div',{staticClass:"font",style:({
                fontFamily: _vm.getCurrentSmbCustomer.brand_font_families[1],
              })},[_vm._v(" "+_vm._s(_vm.getCurrentSmbCustomer.brand_font_families[1])+" ")]),_c('div',{staticClass:"fonts-container__item-controls"},[_c('i',{staticClass:"icon-edit",on:{"click":function($event){return _vm.clickOnFontIcoHandler(
                    true,
                    1,
                    _vm.getCurrentSmbCustomer.brand_font_families[1]
                  )}}}),_c('i',{staticClass:"icon-remove",on:{"click":function($event){return _vm.deleteFontHandler(1)}}})])]):_c('div',{staticClass:"fonts-container__add-font",on:{"click":function($event){return _vm.clickOnFontIcoHandler(false, 1, 'Open Sans')}}},[_vm._v(" + Add font ")])])])]):_vm._e(),(_vm.isShowHashtagsBlock)?_c('div',{staticClass:"hashtags"},[_c('div',{staticClass:"hashtags__title"},[_vm._v(" Hashtags "),_c('span',{staticClass:"hashtags__amount"},[_vm._v("("+_vm._s(_vm.getCurrentSmbCustomer.hashtags_constant.length)+"/30)")])]),(_vm.isShowHashtagsBlock)?_c('div',{staticClass:"hashtags__list"},[_c('div',{staticClass:"hashtags__list-add-more"},[_c('v-icon',{staticClass:"edit-info",on:{"click":function($event){return _vm.$router.push({ name: 'settings-hashtags' })}}},[_vm._v("mdi-chevron-right ")])],1),_vm._l((_vm.getCurrentSmbCustomer.hashtags_constant),function(item,index){return _c('span',{key:index,staticClass:"hashtags__list-item",on:{"click":function($event){return _vm.$router.push({ name: 'settings-hashtags' })}}},[_vm._v(_vm._s(item))])})],2):_vm._e()]):_vm._e(),_c('ModalDialog',{attrs:{"isShow":_vm.fontDialogIsShow,"customClass":"edit-font","title":_vm.fontPopUpTitle,"footerButtons":_vm.fontPopUpBtn,"isLoading":_vm.isLoadingPopUp},on:{"save":_vm.saveFontHandler,"close":_vm.closeFontPopUpHandler},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',{staticClass:"choose-font-pop-up"},[_c('span',{staticClass:"apply-font",style:({
              fontFamily: _vm.fontValue,
            })},[_vm._v("This is text with selected font")]),_c('div',{staticClass:"choose-font-select"},[_c('div',{staticClass:"select-wrapper"},[_c('span',[_vm._v(" Sort by: ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sortBy),expression:"sortBy"}],staticClass:"select",attrs:{"name":"sortBy","id":"select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.sortBy=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"alpha"}},[_vm._v("Alphabetically")]),_c('option',{attrs:{"value":"popularity"}},[_vm._v("Popularity")]),_c('option',{attrs:{"value":"trending"}},[_vm._v("Trending")])])]),_c('v-select',{attrs:{"items":_vm.fontsListWithLimit,"label":"Fonts","item-text":"family","item-value":"family"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{style:({
                    fontFamily: item.family,
                  })},[_vm._v(" "+_vm._s(item.family)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('span',{style:({
                    fontFamily: item.family,
                  })},[_vm._v(_vm._s(item.family))])]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}]})]},proxy:true}]),model:{value:(_vm.fontValue),callback:function ($$v) {_vm.fontValue=$$v},expression:"fontValue"}})],1)])]},proxy:true}])}),_c('ModalDialog',{attrs:{"isShow":_vm.removeBgDialog.isShow,"title":_vm.removeBgDialog.title,"footerButtons":_vm.removeBgDialog.footerButtons,"isLoading":_vm.removeBgDialog.isLoading,"isClickOutside":false,"isShowSlotFooter":false,"customClass":"remove-bg-dialog"},on:{"success":_vm.approveToRemoveBg,"cancel":_vm.canceledRemoveBg,"close":_vm.closeRemoveBgHandler,"acceptToSetNewImg":_vm.acceptToSetNewImgHandler,"cancelToUpdate":_vm.cancelToUpdate,"successToRemoveBusinessAsset":_vm.successToRemoveBusinessAssetHandler,"cancelToRemoveBgForBusinessAsset":_vm.cancelToRemoveBgForBusinessAssetHandler,"acceptToSetBusinessAsset":_vm.acceptToSetBusinessAssetHandler},scopedSlots:_vm._u([(_vm.removeBgDialog.isShowImgWithoutBg)?{key:"description",fn:function(){return [_c('div',{staticClass:"img-wrapper"},[_c('img',{staticClass:"img-without-bg",attrs:{"src":_vm.imgUrlWithoutBg,"alt":_vm.imgUrlWithoutBg}})])]},proxy:true}:null],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }